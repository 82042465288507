export enum EventName {
  login = 'login',
  sign_up = 'sign_up',
  search = 'search',
  begin_checkout = 'begin_checkout',
  purchase = 'purchase',
  purchase_fail = 'purchase_fail',

  // header
  header_Home = 'header_Home',
  dmca_Home = 'dmca_Home',
  header_Plan = 'header_Plan',
  header_FAQ = 'header_FAQ',
  header_Analytics_Sample = 'header_Analytics_Sample',
  header_star_data = 'header_star_data',
  header_Blog = 'header_Blog',
  free_tools = 'free tools',
  header_SignIn = 'header_SignIn',
  header_Dashboard = 'header_Dashboard',
  header_Settings = 'header_Settings',
  header_Payment_History = 'header_Payment_History',
  header_Logout = 'header_Logout',
  header_tool = 'header_tool',
  header_service = 'header_service',
  header_onlyfans_protection = 'header_onlyfans_protection',
  header_remove_fake_account = 'header_remove_fake_account',
  header_cam_model_protection = 'header_cam_model_protection',
  header_onlyfans_competitors = 'header_onlyfans_competitors',
  header_affiliate = 'header_affiliate',
  // login
  // login_Google = 'login_Google',
  // login_Sign_In = 'login_Sign_In',
  // login_Sign_Up = 'login_Sign_Up',
  login_Forgot_Password = 'login_Forgot_Password',

  // home
  home_See_Analytics_Sample = 'home_See_Analytics_Sample',
  home_ADD_ACCOUNT = 'home_ADD_ACCOUNT',

  // dashboard
  dashboard_ADD_ACCOUNT = 'dashboard_ADD_ACCOUNT',
  dashboard_See_Analytics = 'dashboard_See_Analytics',
  dashboard_Buy_past_reports = 'dashboard_Buy_past_reports',
  dashboard_Buy_past_reports_btn = 'dashboard_Buy_past_reports_btn',
  dashboard_Delete = 'dashboard_Delete',
  dashboard_Check = 'dashboard_Check',
  dashboard_Add_new_account = 'dashboard_Add_new_account',

  // settings
  settings_Password_Change = 'settings_Password_Change',
  settings_Plan_Stop = 'settings_Plan_Stop',
  settings_Plan_Subscription = 'settings_Plan_Subscription',
  settings_Logout = 'settings_Logout',
  settings_Delete_Account = 'settings_Delete_Account',

  // 访问用户数：
  // 初次访问用户数：first_visit
  // 点击注册用户数：
  Login_View = 'login_View',
  Blog_Detail_Bottom_AddAccount_Btn = 'Blog_Detail_Bottom_AddAccount_Btn',

  // 注册成功用户数：
  user_register_Success = 'user_register_Success',
  // 注册失败用户数：
  user_register_Fail = 'user_register_Fail',
  // 登录用户数：
  user_Login_Success = 'user_Login_Success',

  // 登录用户样本分析访问用户数：
  Sample_View = 'sample_View',
  // 样本详情页访问用户数（3个样本页面排重后的合集用户）：
  Sample_See_Analytics = 'sample_See_Analytics',
  // 点击“Ckeck”用户数（只要尝试点击ADD ACCOUNT，一定添加成功）：
  // dashboard_Check = 'dashboard_Check',
  // “ADD ACCOUNT”成功添加用户数：
  Add_Account_Request_Success = 'add_Account_Request_Success',
  // Plan页面访问用户数：
  Plan_View = 'plan_View',
  // Plan页面点击“Subscribe”用户数：
  Plan_Subscribe_Request_Before = 'plan_Subscribe_Request_Before',
  // “Subscribe”成功用户数：
  Plan_Subscribe_Request_Success = 'Plan_Subscribe_Request_After',
  // “Subscribe”失败用户数：
  Plan_Subscribe_Request_Fail = 'plan_Subscribe_Request_Fail',

  // 点击“Buy past report”用户数：
  // dashboard_Buy_past_reports = 'dashboard_Buy_past_reports',
  // “Buy past report”成功用户数：
  Buy_Past_Report_Request_Success = 'buy_Past_Report_Request_Success',
  // “Buy past report”失败用户数：
  Buy_Past_Report_Request_Fail = 'buy_Past_Report_Request_Fail',

  // 关闭
  Article_Bottom_Banner_Close_Icon = 'Article_Bottom_Banner_Close_Icon',
  // 跳转
  Article_Bottom_Banner_Click = 'Article_Bottom_Banner_Click',

  // COMMON_VIEW = 'common_view',
  // PRODUCT_VIEW = 'item_view',
  // SHOP_VIEW = 'designer_view',
  // USER_REGISTER = 'user_register',
  // ADD_TO_CART = 'add_to_cart',
  // INITIATE_CHECKOUT = 'initiate_checkout',
  User_AddModal_Ok = 'User_AddModal_Ok',
  User_addModal_Verify = 'User_addModal_Verify',
  User_addModal_Copy = 'User_addModal_Copy',

  // report
  Report_detail_Unlock = 'Report_detail_Unlock',
  Report_detail_Follow_us = 'Report_detail_Follow_us',
  Report_detail_Compare_search = 'Report_detail_Compare_search',
  Report_detail_dashboar_Left_Unlock = 'Report_detail_dashboar_Left_Unlock',
  Report_detail_dashboar_See_more_Unlock = 'Report_detail_dashboar_See_more_Unlock',
}
