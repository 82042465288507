/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-require-imports */
import React, { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import '@/styles/mixins.less';
import '@/styles/fonts.css';
import '@/styles/globals.less';
import 'nprogress/nprogress.css';
import '@/layouts/index.less';
import ConfigProvider from '@/context/ConfigProvider';
import UserConfigProvider from '@/context/UserProvider';
import UserProvider from '@/context/UserProvider';

import withTheme from '@/theme';
import { appWithTranslation } from 'next-i18next';
import { Locale } from 'antd/lib/locale';
import { CurrentLanguage } from '@/shared/language';
const Login = dynamic(() => import('@/components/Login'), {
  ssr: false,
});
import { App as AntdApp } from 'antd';
import dynamic from 'next/dynamic';
const LoginResult = dynamic(() => import('@/components/LoginResult'), {
  ssr: false,
});

// import { Inter } from 'next/font/google';
// const inter = Inter({
//   subsets: ['latin'],
//   weight: ['400', '500', '600', '700'],
//   display: 'swap',
//   preload: true,
//   fallback: ['system-ui', '-apple-system', 'Arial'],
// });

import { AppSettings } from '@/shared/app-common';

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeError', NProgress.done);
Router.events.on('routeChangeComplete', NProgress.done);

require('../styles/ins-theme.less');

const CookieConsentContent = dynamic(() => import('@/components/Common/CookieConsent'), {
  ssr: false,
});
export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<
  P = {
    dehydratedState?: unknown;
  },
> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};
const setupRouterEvents = () => {
  Router.events.on('routeChangeStart', NProgress.start);
  Router.events.on('routeChangeError', NProgress.done);
  Router.events.on('routeChangeComplete', NProgress.done);

  return () => {
    Router.events.off('routeChangeStart', NProgress.start);
    Router.events.off('routeChangeError', NProgress.done);
    Router.events.off('routeChangeComplete', NProgress.done);
  };
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  // const jsonData = useMemo(() => getJsonData(), []);

  useEffect(() => {
    // 设置路由事件
    const cleanup = setupRouterEvents();
    return cleanup;
  }, []);

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  // 7. 优化source处理
  useEffect(() => {
    if (router.isReady && !localStorage.getItem('source') && router.query.source) {
      localStorage.setItem('source', router.query.source as string);
    }
  }, [router.isReady, router.query.source]);

  useEffect(() => {
    // 仅在客户端执行
    if (typeof window === 'undefined') return;
    const loadClarityScript = () => {
      const timer = setTimeout(() => {
        const script = document.createElement('script');
        script.innerHTML = `(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${AppSettings.clarityId}");`;
        document.body.appendChild(script);
        const rewardfulScript = document.createElement('script');
        rewardfulScript.innerHTML = `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`;
        document.body.appendChild(rewardfulScript);
        const rewardfulScript2 = document.createElement('script');
        rewardfulScript2.src = 'https://r.wdfl.co/rw.js';
        rewardfulScript2.setAttribute('data-rewardful', 'e19b2f');
        rewardfulScript2.setAttribute('async', 'true');
        document.body.appendChild(rewardfulScript2);
        return () => {
          clearTimeout(timer);
        };
      }, 0);
    };

    if (document.readyState === 'complete') {
      loadClarityScript();
    } else {
      window.addEventListener('load', loadClarityScript);
      return () => window.removeEventListener('load', loadClarityScript);
    }
  }, []);

  return withTheme(
    <div>
      <AntdApp>
        <ConfigProvider>
          <UserProvider>
            <UserConfigProvider>
              {getLayout(<Component {...pageProps} />)}
              <Login />
              <LoginResult />
              <CookieConsentContent />
            </UserConfigProvider>
          </UserProvider>
        </ConfigProvider>
      </AntdApp>
    </div>,
    loc,
  );
}

export default appWithTranslation(App);
